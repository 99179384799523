import { Breadcrumb } from 'antd';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import actions from "Store/Actions";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
const Wrapper = styled(Breadcrumb)`
  font-style: normal;
  font-weight: 800;
  font-size: 1.6rem;
  line-height: 2rem;
  padding-bottom: 2rem;
  .ant-breadcrumb-link{
    display: flex;
    
    .breadcrumb-point{
      height: 2rem;
      width: 0.58rem;
      background-color: #7D0006;
      margin-right: 1rem;
    }
    
    .breadcrumb-name, a {
      text-decoration: none;
      color: #7D0006;

      &:hover{ 
        background-color:transparent;
        color: #5a0004;
        cursor: pointer;
      }
    }
    a{
      &:hover{
        opacity: 0.7;
        background-color: transparent;
        cursor: pointer;
      }
    }
    .breadcrumb-name{
      cursor: auto !important;
    }
  }

  
`;

const BreadcrumbComponent = ({
  name,
  reset,
  idStudent,
  isGenCourse,
  clearStore,
  icon,
  ...rest
}) => {
  const {
    currentStudentInformation,
    generatedClassScheduleID,
    studentID
  } = useSelector((state) => state.student);
  const {
    resetAllInfomation,
    resetCurriculum
  } = actions;
  const dispatch = useDispatch();
  let location = useLocation();
  const params = useParams()
  const handleBreadcrumb = () => {
    if (!currentStudentInformation?.backupData || (location.pathname.includes('/student/curriculum')&& location.pathname.includes('/create'))) {
      dispatch(
        resetAllInfomation({})
      )
    }
    if (generatedClassScheduleID.length > 0 && location.pathname.includes('/student/curriculum')) {
      dispatch(
        resetCurriculum({})
      )
    }

  }
  const generatePathname = () => {
    // if (currentStudentInformation?.backupData && location.pathname.includes('/result-lesson')) {
    if (currentStudentInformation?.backupData && location.pathname.includes('/lesson-results')) {
      return `/student/curriculum/${currentStudentInformation?.studentID }/create`;
    }
    // else if (generatedClassScheduleID.length > 0 && location.pathname.includes('/result-lesson')) {
    else if (generatedClassScheduleID.length > 0 && location.pathname.includes('/lesson-results')) {
      if (currentStudentInformation.version <= process.env.REACT_APP_VERSION) {
        return `/student/curriculum-detail/${currentStudentInformation?.studentID}/${params?.curriculum ==='create' ? '1' : params?.curriculum}`;
      }
      else {
        return `/student/curriculum/${currentStudentInformation?.studentID}/${params?.curriculum ==='create' ? '1' : params?.curriculum}`;
      }
    } else {
      return `/student/${currentStudentInformation?.studentID || studentID}`;
    }
  };
  return (
    <Wrapper {...rest}>
      <Breadcrumb.Item onClick={reset} className={!clearStore && 'backhome'}>
        
        {idStudent || currentStudentInformation?.studentID || location.pathname.includes('/create') ? (
          <Link to={{
            pathname: generatePathname(),
            state: { noUni: clearStore }
          }}
            onClick={handleBreadcrumb}
            className='d-flex align-items-center'
          >{icon}{name}</Link>
        ) : (
            <>
              <div className="breadcrumb-point"></div>
              <div className="breadcrumb-name">{name}</div>
            </>
        )}
      </Breadcrumb.Item>
    </Wrapper>
  )
}

export default BreadcrumbComponent
